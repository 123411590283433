import { useFormContext } from 'react-hook-form';
import { Stack } from '@mui/material';
import { RHFTextField } from '#/components/shared/hook-form';
import Iconify from '#/components/shared/ui/Iconify';

interface AdornmentInputProps {
  name: string;
  label: string;
}

export function AdornmentInput({ name, label }: AdornmentInputProps) {
  const {
    formState: {
      errors: { name: fieldError },
      dirtyFields,
    },
  } = useFormContext();

  const isEmpty = !dirtyFields[name];

  return (
    <Stack
      spacing={1}
      sx={{
        width: '100%',
      }}
    >
      <RHFTextField
        required
        variant="outlined"
        name={name}
        label={label}
        InputProps={{
          ...(!fieldError &&
            !isEmpty && {
              endAdornment: (
                <Iconify icon="eva:checkmark-circle-2-fill" color="#54D62C" />
              ),
            }),
        }}
      />
    </Stack>
  );
}
