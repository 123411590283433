import { Box, MenuItem, Stack } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  RHFDatePicker,
  RHFSelect,
  RHFTextField,
} from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import {
  CIVIL_STATUS_OPTIONS,
  GENDER_OPTIONS,
} from '#/components/pages/PensionProvision/onboarding/data';
import PhoneInput from '#/components/shared/hook-form/PhoneInput';
import Iconify from '#/components/shared/ui/Iconify';
import RHFStyledRadioGroup from '#/components/shared/hook-form/RHFStyledRadioGroup';
import { AdornmentInput } from './adornment-input';

export default function CoreForm() {
  const {
    watch,
    setValue,
    getValues,
    formState: { dirtyFields },
  } = useFormContext();
  const { translate } = useLocales();

  const handlePhoneChange = (
    value: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue('mobile_phone', value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const renderDoneIcon = (name: string) =>
    getValues(name) ? (
      <Iconify icon="eva:checkmark-circle-2-fill" color="#54D62C" />
    ) : null;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <RHFStyledRadioGroup
        name="provision_for"
        question={{
          questionKey: 'provision_for',
          question: String(
            translate(
              'pensionProvision.onboarding.stepOne.questions.0.question'
            )
          ),
          answers: [
            {
              label: String(
                translate(
                  `pensionProvision.onboarding.stepOne.questions.0.options.0.label`
                )
              ),
              value: 'self',
            },
            {
              label: String(
                translate(
                  `pensionProvision.onboarding.stepOne.questions.0.options.1.label`
                )
              ),
              value: 'family',
            },
          ],
        }}
      />
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
        <AdornmentInput
          name="first_name"
          label={String(translate('global.formLabels.firstName'))}
        />

        <AdornmentInput
          name="last_name"
          label={String(translate('global.formLabels.lastName'))}
        />
        <PhoneInput
          name="mobile_phone"
          label={String(translate('global.formLabels.mobilePhone'))}
          // value={watch('mobile_phone')}
          value={dirtyFields.mobile_phone ? getValues('mobile_phone') : ''}
          onChange={(e) => handlePhoneChange(e)}
          sx={{ width: '100%' }}
        />
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={2}
          sx={{ width: '100%' }}
        >
          <RHFDatePicker
            required
            variant="outlined"
            name="date_of_birth"
            label={String(translate('global.formLabels.date_of_birth'))}
            InputProps={{
              ...(watch('date_of_birth') && {
                endAdornment: renderDoneIcon('date_of_birth'),
              }),
            }}
          />
          <RHFSelect
            required
            name="gender"
            label={String(translate('global.gender'))}
            InputProps={{
              ...(watch('gender') && {
                endAdornment: (
                  <Iconify
                    icon="eva:checkmark-circle-2-fill"
                    color="#54D62C"
                    sx={{ marginRight: 2, width: 24, height: 24 }}
                  />
                ),
              }),
            }}
          >
            {GENDER_OPTIONS.map((g, i) => (
              <MenuItem key={i} value={g.value}>
                {String(translate(`global.${g.label}`))}
              </MenuItem>
            ))}
          </RHFSelect>
        </Stack>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={2}
          sx={{ width: '100%' }}
        >
          <RHFSelect
            required
            variant="outlined"
            name="civil_status"
            label={String(translate('global.formLabels.civilStatus'))}
            InputProps={{
              ...(watch('civil_status') && {
                endAdornment: (
                  <Iconify
                    icon="eva:checkmark-circle-2-fill"
                    color="#54D62C"
                    sx={{ marginRight: 2, width: 24, height: 24 }}
                  />
                ),
              }),
            }}
          >
            {CIVIL_STATUS_OPTIONS.map((status) => (
              <MenuItem key={status.code} value={status.code}>
                {String(translate(`global.civilStatus.${status.code}`))}
              </MenuItem>
            ))}
          </RHFSelect>
          <RHFTextField
            required
            name="number_of_children"
            label={String(translate('global.formLabels.children_count'))}
            InputProps={{
              ...(watch('number_of_children') && {
                endAdornment: renderDoneIcon('number_of_children'),
              }),
            }}
          />
        </Stack>
      </Stack>
      <RHFStyledRadioGroup
        name="have_real_estate"
        question={{
          questionKey: 'have_real_estate',
          question: String(
            translate(
              'pensionProvision.onboarding.stepOne.questions.1.question',
              {
                referringTo: translate(
                  `pensionProvision.onboarding.stepOne.${
                    getValues('provision_for') === 'self'
                      ? 'myRealEstate'
                      : 'theirRealEstate'
                  }`
                ),
              }
            )
          ),
          answers: [
            {
              label: String(
                translate(
                  `pensionProvision.onboarding.stepOne.questions.1.options.0.label`
                )
              ),
              value: 'yes',
            },
            {
              label: String(
                translate(
                  `pensionProvision.onboarding.stepOne.questions.1.options.1.label`
                )
              ),
              value: 'no',
            },
          ],
        }}
      />
      <AdornmentInput
        name="email"
        label={String(translate('global.formLabels.emailAddress'))}
      />
    </Box>
  );
}
